import React, { useState } from 'react';
import './Dashboard.css'; 

const Dashboard = () => {
    const timeRanges = ["24 hours", "7 days", "30 days", "1 year"];
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleClick = () => {
        setCurrentIndex((currentIndex + 1) % timeRanges.length);
    }

    return (
        <div className="dashboard">
            <h1>Dashboard Page</h1>
            <div className="stat-container">
                <div className="stat-card">
                    <h2>Attacks in the last <span onClick={handleClick} className="time-range">{timeRanges[currentIndex]}</span></h2>
                    <h1>6</h1> 
                </div>
                <div className="stat-card">
                    <h2>Bandwidth Dropped in the last <span onClick={handleClick} className="time-range">{timeRanges[currentIndex]}</span></h2>
                    <h1>42.64 MB</h1>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;