import React from 'react';
import './Filters.css';
import underconstruction from './underconstruction.jpg'; // Import the image

const Filters = () => {
    return (
        <div className="Filters">
            <h1>Filters Page</h1>
            <p>The filters page will allow you to make custom filters for your IPs.</p>
            <img src={underconstruction} className="under-construction" alt="Page Under Construction" /> {/* Use the image here */}
        </div>
    );
};

export default Filters;