import React from 'react';
import './Attacks.css'; 

const Attacks = () => {
    return (
        <div className="attacks">
            <h1>Attacks Page</h1>
            <div className="stat-card" style={{ width: "99.5%" }}>
                <h2>Attack details</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Host</th>
                            <th>Reason</th>
                            <th>Start time</th>
                            <th>End time</th>
                            <th>Peak pps</th>
                            <th>Peak bps</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>167.94.253.5</td>
                            <td>packet threshold reached</td>
                            <td>2023-07-24 02:12:50</td>
                            <td>2023-07-24 02:14:50</td>
                            <td>1003.13 Kpkt/s</td>
                            <td>5.32 Tb/s</td>
                        </tr>
                        <tr>
                            <td>167.94.253.212</td>
                            <td>byte threshold reached</td>
                            <td>2023-07-24 02:12:50</td>
                            <td>2023-07-24 02:14:50</td>
                            <td>487.5 Kpkt/s</td>
                            <td>3.95 Gb/s</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Attacks;