import React from 'react';
import './App.css';
import HyperShield from './HyperShield';

function App() {
  return (
    <div className="App">
      <HyperShield />
    </div>
  );
}

export default App;