import React from 'react';
import './YourIPs.css'; // If you want to use specific styles for this tab

const YourIPs = () => {
    return (
        <div className="YourIPs">
            <h1>Your Dedicated IPs</h1>
            {/* You can add more content here, tables, charts, anything you need for your YourIPs page */}
            <p>This is where the content for the YourIPs page goes.</p>
            <div className="stat-card" style={{ width: "99.5%" }}>
                <table>
                    <thead>
                        <tr>
                            <th>IP</th>
                            <th>Service</th>
                            <th>Total Traffic</th>
                            <th>Activation Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>167.94.253.5</td>
                            <td>Minecraft Hosting</td>
                            <td>86.2TB</td>
                            <td>2023-03-12 02:14:50</td>
                        </tr>
                        <tr>
                            <td>167.94.253.212</td>
                            <td>Dedicated Server</td>
                            <td>12.6TB</td>
                            <td>2023-06-30 02:14:50</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default YourIPs;