import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './HyperShield.css'; 
import background from './background.svg';
import logo from './sidebar-logo.svg';
import dashboard from './tabs/Dashboard.svg';
import tab1 from './tabs/tab1.svg';
import tab2 from './tabs/tab2.svg';
import tab3 from './tabs/tab3.svg';
import tab4 from './tabs/tab4.svg';
import tab5 from './tabs/tab5.svg';
import tab6 from './tabs/tab6.svg';
import tab7 from './tabs/tab7.svg';

// Import the components for each of your tabs
import Dashboard from './tabs/Dashboard';
import Attacks from './tabs/Attacks';
import Filters from './tabs/Filters';
import Rules from './tabs/Rules';
import Subusers from './tabs/Subusers';
import Settings from './tabs/Settings';
import Layer7 from './tabs/Layer7';
import YourIPs from './tabs/YourIPs';

const HyperShield = () => {
    return (
        <div className='container' style={{ backgroundImage: `url(${background})` }}>
            <Router>
                <div className='inner-container'>
                    <div className='sidebar'>
                        <img src={logo} className='logo' alt='Logo' />
                        <Link to="/dashboard"><img src={dashboard} className='tab-icon' alt='Dashboard' /></Link>
                        <Link to="/attacks"><img src={tab1} className='tab-icon' alt='Tab 1' /></Link>
                        <Link to="/filters"><img src={tab2} className='tab-icon' alt='Tab 2' /></Link>
                        <Link to="/rules"><img src={tab3} className='tab-icon' alt='Tab 3' /></Link>
                        <Link to="/subusers"><img src={tab4} className='tab-icon' alt='Tab 4' /></Link>
                        <Link to="/settings"><img src={tab5} className='tab-icon' alt='Tab 5' /></Link>
                        <Link to="/layer7"><img src={tab6} className='tab-icon' alt='Tab 6' /></Link>
                        <Link to="/your-ips"><img src={tab7} className='tab-icon' alt='Tab 7' /></Link>
                    </div>
                    <div className='content'>
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/attacks" element={<Attacks />} />
                            <Route path="/filters" element={<Filters />} />
                            <Route path="/rules" element={<Rules />} />
                            <Route path="/subusers" element={<Subusers />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/layer7" element={<Layer7 />} />
                            <Route path="/your-ips" element={<YourIPs />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </div>
    );
};

export default HyperShield;