import React from 'react';
import './Layer7.css'; // If you want to use specific styles for this tab
import underconstruction from './underconstruction.jpg'; // Import the image

const Layer7 = () => {
    return (
        <div className="Layer7">
            <h1>Layer7 Page</h1>
            {/* You can add more content here, tables, charts, anything you need for your Layer7 page */}
            <p>This is where the content for the Layer7 page goes.</p>
            <img src={underconstruction} className="under-construction" alt="Page Under Construction" /> {/* Use the image here */}
        </div>
    );
};

export default Layer7;