import React from 'react';
import './Subusers.css'; // If you want to use specific styles for this tab
import underconstruction from './underconstruction.jpg'; // Import the image

const Subusers = () => {
    return (
        <div className="Subusers">
            <h1>Subusers Page</h1>
            {/* You can add more content here, tables, charts, anything you need for your Subusers page */}
            <p>This is where the content for the Subusers page goes.</p>
            <img src={underconstruction} className="under-construction" alt="Page Under Construction" /> {/* Use the image here */}
        </div>
    );
};

export default Subusers;